import { h, Component } from "preact";
import { Router } from "preact-router";

import style from "./style";

// Code-splitting is automated for routes
import Home from "../routes/home";
import Error from "../routes/error";

export default class App extends Component {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = (e) => {
    this.currentUrl = e.url;
  };

  render() {
    return (
      <div id="app" className={`${style.app} h-screen mx-auto`}>
        <Router onChange={this.handleRoute}>
          <Home path="/next" />
          <Home path="/" />
          <Error type="404" default />
        </Router>
      </div>
    );
  }
}
